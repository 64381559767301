<template>
  <v-card
      :tile="textCard"
      elevation="1"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      min-height="280" >
    <MainModal
        :main="{ component: 'PrivilegePersons', title: 'Перелік пільговиків' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_privilege_persons)"
        :modal="show_privilege_persons_dialog"
        :nonClickable="nonClickable"
        @updateItemModal="privilegePersonsUpdateItemModal"
    />
    <v-card-text class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Перелік пільговиків
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success" v-if="!readOnly && privilege_selected.id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openPrivilegePersonsCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити пільговиків</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-text>
    <v-data-table
        :headers="familyHeaders"
        :items="items"
        hide-default-footer
        :class="bigger ? 'custom-table-bigger' : ''"
        class="custom-table custom-table-1"
        @click:row="onPrivilegePersonsItemClick"
    >
      <template v-slot:item.selected="{ item }">
        <v-simple-checkbox color="secondary" :value="selected === item.id" :ripple="false" @input="changeSelectPrivilegeOwner(item)"/>
      </template>
      <template v-slot:item.icon>
        <v-icon :color="'success'" size="26" class="mr-2">
          {{ 'mdi-account-arrow-right-outline' }}
        </v-icon>
      </template>
      <template v-slot:item.persons="{ item }">
        <span class="font-weight-medium">
          {{ item.persons }}
        </span>
      </template>
      <template v-slot:item.date_start="{ item }">
        {{ item.date_start | formatDate }}
      </template>
      <template v-slot:item.date_end="{ item }">
        {{ item.date_end | formatDate }}
      </template>
      <template v-slot:item.create_date="{ item }">
        {{ item.create_date | formatDate }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {
  FETCH_PRIVILEGE_PERSONS,
} from "@/store/actions/privilege";

export default {
  name: "HWP_PrivilegePersons",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Поточний стан розрахунку по абоненту'
    },
    person_hash: {
      type: String,
      default: ''
    },
    bigger: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MainModal
  },
  computed: {
    ...mapGetters({
      items: 'getPrivilegePersons',
      privilege_selected: 'getPrivilegeSelected'
    })
  },
  data() {
    return {
      familyHeaders: [
        { text: '', value: 'icon', width: 60 },
        { text: 'Пільговиків', value: 'persons' },
        { text: 'Дата з', value: 'date_start' },
        { text: 'Дата по', value: 'date_end' },
        { text: 'Дата створення', value: 'create_date' }
      ],
      selected_privilege_persons: {},
      show_privilege_persons_dialog: false,
      selected: false
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_PRIVILEGE_PERSONS,
    }),
    privilegePersonsUpdateItemModal() {
      this.show_privilege_persons_dialog = false
      this.selected_privilege_persons = {}
    },
    openPrivilegePersonsCreateDialog() {
      this.selected_privilege_persons = {
        personal: this.personal
      }
      this.show_privilege_persons_dialog = true
    },
    onPrivilegePersonsItemClick(payload) {
      if (this.readOnly || this.nonClickable) {
        this.selected_privilege_persons = {}
        this.show_privilege_persons_dialog = false
        return
      }
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.personal = this.personal
      this.selected_privilege_persons = local_payload
      this.show_privilege_persons_dialog = true
    },
  },
  created() {
    if (this.privilege_selected.id) {
      const payload = {
        flat_id: this.privilege_selected.flat_id,
        privilege_owner_id: this.privilege_selected.id
      }
      this.fetch(payload)
    }
  },
  watch: {
    privilege_selected: {
      immediate: true,
      handler(payload) {
        if (payload.id) {
          const payload_for_fetch = {
            flat_id: payload.flat_id,
            privilege_owner_id: payload.id
          }
          this.fetch(payload_for_fetch)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-table-bigger {
  :deep(.v-data-table__wrapper) {
    tbody {
      tr {
        td {
          height: 40px !important;
        }
      }
    }
    thead {
      tr {
        th {
          height: 46px !important;
        }
      }
    }
  }
}
</style>